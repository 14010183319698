section .contact-text strong {
    font-weight: bold;
}

section .contact-box {
    width: 100%;
    position: static;
    left: 20%;
}

section .contact-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 25px;
    border-bottom: 1px solid var(--color-primary);
    border-bottom-style: dashed;
}

section .contact-wrapper p {
    font-weight: bold !important;
    color: var(--color-primary);
    font-size: 14px;
}

.material-symbols-outlined {
    vertical-align: middle;
}

section .text-description, section .title  {
    margin: 30px;
}

section .contact-wrapper .text-infos {
    font-size: 14px;
    min-width: 222px;
}

section .contact-wrapper .text-logo {
    padding: 9px 0;
}

@media (max-width: 768px) {
    section .contact-wrapper {
        flex-direction: column;
    }

    section .contact-wrapper .text-logo {
        padding-bottom: 15px;
    }
}