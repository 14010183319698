.accordion-body, .accordion-button {
    background-color: var(--color-green-lighter);
}

.accordion-button:not(.collapsed) {
    background-color: var(--color-primary);
    color: var(--color-secondary);
    font-weight: bolder;
}

.accordion-button:hover {
    background-color: var(--color-primary);
    color: var(--color-secondary);
}

.accordion-item {
    max-width: 1200px;
}

section h3 {
    color: var(--color-primary);
    font-weight: bolder;
    margin: 20px 0;
}

.accordion-button{
    color: var(--color-primary);
    font-weight: bolder;
}

.accordion-body {
    background-color: var(--color-green-lighter);
}

.furnizori-wrapper {
    display: flex;
    max-width: 1200px;
    justify-content: space-between;
    margin: 10px;
    padding: 5px;
    border-bottom: 1px solid var(--color-primary);
    border-radius: 10px;
}

.furnizori-wrapper p {
    margin: auto 5px;
    color: var(--color-primary);
    font-weight: bold;
}

@media (max-width: 768px) {
    .furnizori-wrapper {
        flex-direction: column;
    }
}

.parteneri-wrapper {
    max-width: 500px;
    border-bottom: 1px solid var(--color-primary);
    border-radius: 10px;
    margin: 10px auto;
    padding: 5px;

}