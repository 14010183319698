

.Card {
    background-color: var(--color-green-lighter);
}

.Card{
    box-shadow: 7px 7px 20px 2px var(--color-primary);
    transform: scale(1.0075);
                
}

.card-header {
    padding: 10px !important;
}

section .card-title {
    background-color: var(--color-primary);
    color: var(--color-secondary);
    font-weight: bold;
}

section .card-text {
    font-style: italic;
    font-weight: 550;
}
