.portofoliu .container-images {
  width: 60%;
  height: auto;
}

.portofoliu .title {
  margin: 20px;
}

.portofoliu .container-wrapper {
  max-width: 1200px;
  min-width: 320px;
  display: flex;
  justify-content: center;
  margin: auto;
}

.portofoliu .container-box.container-text {
  display: flex;
  flex-direction: column;
}

.portofoliu .container-box.container-text h1 {
  padding: 20px;
  color: var(--color-primary);
  font-weight: bold;
}

.portofoliu .container-wrapper {
    box-shadow: 0 0 50px 2px var(--color-primary);
    margin-bottom: 20px;
    padding: 5px;
    border-radius: 10px;
}

.portofoliu .carousel-status {
    display: none;
}

@media (max-width: 768px) {
    .portofoliu .container-wrapper {
        flex-direction: column;
        width: 90%;
    }

    .portofoliu .container-images {
        width: 100%;
        height: 100%;
        margin: auto;
    }

    .portofoliu .container-box.container-text h1 {
        padding: 0;
    }
}